<template>
  <div style="background-color: #ffffff;
    margin: 180px auto;
    width: 600px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 15px 4px -15px #d8d8d8;">


    <div style="margin: 10px auto;width: 360px;color: black;text-align: left;">
      小程序管理员：{{ nowUser }}
    </div>

    <div style="margin: 25px auto;width: 360px;display: flex">
      <el-input placeholder="请输入用户ID" v-model="selectUsers.selectUsers" style="margin-right: 8px">
      </el-input>

      <el-button type="primary" style="background-color: #409eff;color: white" icon="el-icon-search"
                 @click="initUser()">搜索
      </el-button>
    </div>

    <div style="margin: 10px auto">
      <el-select v-model="value" placeholder="请选择用户" class="selectClass">
        <el-option v-for="item in tableData"
                   :key="item.value"
                   :label="item.openIdWx"
                   :value="item.openIdWx" style="width: 360px">

          <span style="float: left;padding-right: 10px">{{ item.id }}</span>

          <span style="float: left">{{ item.openIdWx }}</span>

<!--          <template>-->
<!--            <img :src="item.headImgUrl" @click="ShowpreviewPicS(item.headImgUrl)" min-width="20" height="20"-->
<!--                 style="float: right;"/>-->
<!--          </template>-->


        </el-option>
      </el-select>
    </div>
    <p style="font-size: 12px;color: #8c9396;margin: 20px 120px 0 120px;text-align: left">
      绑定小程序管理员，可以在公众号接收用户的恢复码请求，快捷处理。</p>

    <div v-if="admins == 'true'">
      <el-button style="margin-top: 60px" type="primary" size="medium" @click="modifyWxAdmin()">确认设置</el-button>
    </div>

    <div v-if="admins == 'false'">
      <el-button style="margin-top: 60px" type="primary" size="medium" @click="noPower()">确认设置</el-button>
    </div>


    <el-dialog :visible.sync="lookdialogVisibles" :modal="false" title="头像预览" width="30%">
      <img :src="previewpics" alt="" width="100%"/>
    </el-dialog>

  </div>
</template>


<script>
export default {
  name: "setWxAdmin",

  data() {
    return {
      nowUser: '',
      userNick: '',
      newUser: '',
      tableData: [],
      loading: true,
      lookdialogVisibles: false,
      previewpics: "",
      selectUsers: {
        selectUsers: ''
      },
      admin: {
        unionId: ''
      },
      value: '',
      admins: 'true'
    }
  },
  methods: {

    noPower() {
      this.$message.warning('无权限');
    },

    // 提交按钮
    modifyWxAdmin() {
      console.log("value:", this.value); // 查找框用户昵称
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].openIdWx == this.value) {
          console.log("unionId:", this.tableData[i].unionId)
          this.admin.unionId = this.tableData[i].unionId;
        }
      }

      if (this.value == "") {
        this.$message({
          message: '请选择需要设置的新用户',
          type: 'warning'
        });
        return;
      }
      if (this.value == this.userNick) {
        this.$message({
          message: '当前用户已经是微信管理员',
          type: 'warning'
        });
        return;
      }
      // console.log("this.admin:", this.row.row)

      this.$axios.post(this.url + "/sys/modifyWxAdmin", this.admin).then(res => {
        if (res.data == 200) {
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.$axios.post(this.url + "/sys/adminNickName").then(res => {
            this.nowUser = res.data;
          });
        }
      });
    },

    // 初始化列表为所有用户，搜索条件为空时也是所有数据
    initUser() {
      this.value = "";
      this.$axios.post(this.url + "/sys/initUser", this.selectUsers).then(res => {
        this.tableData = res.data;
        this.loading = false;
        console.log("user:", res.data)
      });
    },

    // 图片预览
    ShowpreviewPicS(url) {
      this.previewpics = url;
      this.lookdialogVisibles = true;
    },

  },

  mounted: function () {   //自动触发写入的函数
    this.$axios.post(this.url + "/sys/adminNickName").then(res => {
      this.nowUser = res.data;
    });
    this.initUser();

    // 获取用户登录身份 手机号或者是登录的用户名
    var user = window.sessionStorage.getItem("user");
    console.log("session user:", user);
    // 后台管理员号码
    this.$axios.post(this.url + "/sys/adminInfo").then(res => {
      var adminPhone = res.data.phone;
      console.log("res data adminPhone:", adminPhone);

      if (user == "DaMuZhi666") {
        this.admins = 'true';
      } else if (user == adminPhone) {
        this.admins = 'true';
      } else {
        this.admins = 'false';
      }

      console.log(" user == adminPhone : ", user == adminPhone)
      console.log(" this.admin : ", this.admins)
    })
  }
}

</script>

<style>

.selectClass > .el-input {
  width: 360px;
}

</style>