<template>
  <div>
    <!--背景-->
    <div class="background" style="width: 100%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="login-ruleForm">
        <div style="margin-top: 20px;">
          <div style="font-size: 22px;line-height: 60px;text-align: center;">虎牌视频教程系统</div>
        </div>
        <div
            style="margin-top: 20px;display: flex;flex-direction: row;margin-left: 20px;margin-bottom: 40px;justify-content: space-between">
        </div>
        <el-form-item label="账户：" prop="username" style="">
          <el-input style="width: 180px; margin-left: -20px" v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="psw" style="">
          <el-input style="width: 180px; margin-left: -20px" v-model="ruleForm.psw" type="password"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 60px;">
          <el-button style="margin-left: -80px;padding: 10px 50px" type="primary" size="medium"
                     @click="submitForm">登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      isPhone: false,
      ruleForm: {
        username: '',
        psw: '',
      },

      click: '1',
      desc: '获取验证码',
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        psw: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
      },
      imgSrc: require('../img/b3.jpeg'),
    };
  },
  methods: {
    goIndex() {
      this.$router.replace("/index");
    },

    // submitForm(formName) {
    submitForm() {
      console.log("clikc");
      this.$axios.post(this.url + "/login", this.ruleForm).then(res => {

        if (res.data !== 200) {//登录失败
          this.$message.error('登录失败，用户名或者密码错误');
        } else {
          this.$message({
            message: '登录成功',
            type: 'success'
          });
          sessionStorage.setItem('isLogin', 'true');
          sessionStorage.setItem("user", this.ruleForm.username); // 用户登录信息
          this.$router.replace("/index");
          setTimeout(function () {
            sessionStorage.clear();
          }, 1000 * 60 * 60 * 2)
        }
      })
    },
  }
}
</script>
<style>
.login-ruleForm {
  text-align: center;
  border-radius: 15px;
  background-clip: padding-box;
  margin: 220px auto;
  /*margin-left: 200px;*/
  width: 280px;
  padding: 15px 35px 15px 35px;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px rgb(25, 202, 233);
  opacity: 1;
}

.background {
  width: 100%;
  height: 100vh;
  /*background: url("../img/b3.jpeg") center center no-repeat;*/
  background: url("../img/back.jpg") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
}
</style>