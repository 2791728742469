<template>

  <div>
  <div style="width: 100%;text-align: center;margin-top: 60px">
    <img src="../img/yhcode.jpg" style="width: 300px;height: 300px">
  </div>
  <div style="width: 100%;text-align: center;margin-top: 20px">
    长按识别关注公众号
  </div>
</div>

</template>

<script>

export default {
  name: "yhqrcode",

  data() {
    return {
      nowPhone: '',
    }
  },

}

</script>

<style>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

</style>