<template>
  <div style="margin: 10px 30px;font-size: 20px;padding: 30px 10px;text-align: center">
    <div style="margin-top: 30px;text-align: center;font-weight: 550">
      发布新品
    </div>

    <div style="margin-top: 80px;display: flex;flex-direction: row;width: 100%;">
      <div style="line-height: 40px;margin-left: 21%"> 设备类:</div>
      <el-select style="margin-left: 1%" v-model="select.select" placeholder="请选择">
        <el-option label="普板类" value="1"></el-option>
        <el-option label="CSP类" value="2"></el-option>
      </el-select>
    </div>

    <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%;">
      <div style="line-height: 40px;margin-left: 21%">主图名:</div>
      <el-input style="width:49%;margin-left: 1%" placeholder="请输入主图名" v-model="deviceImg"></el-input>
      <el-button style="height: 40px" @click="seeDeviceImg()">预览</el-button>
    </div>

    <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
      <div style="line-height: 40px;margin-left: 21%">系列名:</div>
      <el-input style="width:49%;margin-left: 1%" placeholder="请输入标题" v-model="deviceTitle"></el-input>
    </div>


    <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
      <div style="line-height: 40px;margin-left: 21%">标题名:</div>
      <el-input style="width:49%;margin-left: 1%" placeholder="请输入视频标题名称" v-model="videoName"></el-input>
    </div>

    <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
      <div style="line-height: 40px;margin-left: 21%">视频名:</div>
      <el-input style="width:49%;margin-left: 1%" placeholder="请输入视频名" v-model="deviceVideo"></el-input>
      <el-button @click="seeDeviceVideo()">预览</el-button>
    </div>

    <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
      <div style="line-height: 40px;margin-left: 21%">说明书:</div>
      <el-input style="width:49%;margin-left: 1%" placeholder="请输入说明书单页名称，多页以&分割"
                v-model="deviceBook"></el-input>
      <el-button @click="seeDviceBook()">预览</el-button>
    </div>

    <el-button @click="clearInput()" style="margin-top: 80px;width: 120px">清空</el-button>
    <el-button @click="addDevice()" style="margin-top: 30px;width: 120px">提交</el-button>


    <el-dialog :visible.sync="lookImg" :modal="false" title="主图预览" width="34%">
      <img :src="imgUrl" alt="" width="100%"/>
    </el-dialog>

    <el-dialog :visible.sync="lookVideo" :modal="false" title="视频预览" width="34%">
      <div v-for="(pro,index) in videoLists" :key="index" style="margin-top: 30px">
        <video style="width: 34%" controls>
          <source :src="qiniuUrl+pro" type="video/mp4">
        </video>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="book" :modal="false" title="说明书预览" width="34%">
      <div v-for="(pro,index) in bookList" :key="index">
        <img :src="qiniuUrl+pro" alt="" width="100%"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "applyHis",

  data() {
    return {
      select: {
        select: '',
        inputVal: '',
      },
      deviceImg: '',
      deviceTitle: '',
      deviceVideo: '',
      deviceBook: '',
      bookList: [],
      lookImg: false,
      imgUrl: '',
      lookVideo: false,
      videoUrl: "",
      book: false,
      bookUrl: '',
      qiniuUrl: "http://video.u-home-safe.com/",
      videoLists: [],
      videoName: "",
    }
  },

  methods: {
    seeDeviceImg() {
      this.imgUrl = this.qiniuUrl + this.deviceImg;
      this.lookImg = true;
    },
    seeDeviceVideo() {
      this.videoLists = this.deviceVideo.split("&");
      this.lookVideo = true;
    },
    seeDviceBook() {
      this.book = true;
      let book = this.deviceBook;
      this.bookList = book.split("&");
    },
    addDevice() {
      // 提交入库
      console.log(this.select.select);
      if (this.select.select === null || this.select.select === "" || this.deviceImg === ""
          || this.deviceTitle === "" || this.deviceVideo === "" || this.deviceBook === "" || this.videoName === "") {
        this.$message({
          message: '输入不能为空',
          type: 'error'
        });
        return;
      }

      const dataDevice = {
        "type": this.select.select,
        "deviceImg": this.deviceImg,
        "deviceTitle": this.deviceTitle,
        "deviceVideo": this.deviceVideo,
        "deviceBook": this.deviceBook,
        "videoName": this.videoName
      };
      this.$axios.post(this.url + "/adddevice", dataDevice).then(res => {
        console.log("addDevice:", res);
        if (res.status === 200) {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.clearInput();
        } else {
          this.$message({
            message: '发布失败',
            type: 'error'
          });
        }
      })
    },
    clearInput() {
      this.deviceImg = '';
      this.deviceTitle = '';
      this.deviceVideo = '';
      this.deviceBook = '';
      this.videoName = '';
    }
  },

  mounted: function () {

  }
}

</script>

<style>
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 40px;
}
</style>