<template>
  <div style="margin: 0px 20px;font-size: 18px;padding: 0px 10px;text-align: center">
    <div  @click="goBack()"  style="margin-top: 20px;text-align: left;font-weight: 550;font-size: 20px;">
     {{ titile }}设置指导
    </div>
    <!--    <div style="text-align: left;margin-top: 50px">操作视频</div>-->
    <div v-for="(pro,index) in videoList" :key="index" style="margin-top: 50px">
      <div style="margin-top: 30px;text-align: left;font-weight: bold">** {{videoNameList[index]}} **</div>
      <div style="background-color: #000000;width: 100%;margin-top: 20px;border-radius: 10px">
        <video style="margin: 10px auto;border-radius: 10px" width="300" height="200"  controls="controls"
             :poster="qiniuUrl+nameTi" objectFit="cover">
          <source :src="qiniuUrl+pro" type="video/mp4"/>
          <source :src="qiniuUrl+pro" type="video/webm"/>
        </video>
      </div>
    </div>

    <div style="text-align: left;margin-top: 50px;font-weight: bold">** {{ titile }}电子说明书 **</div>
    <div style="height: 30px"></div>
    <div v-for="(pro,indexs) in bookList" :key="indexs">
      <img @click="seeBook1(qiniuUrl+pro)" :src="qiniuUrl+pro" alt="" width="100%"/>
    </div>

    <!--    <el-dialog :visible.sync="lookImg" :modal="false" width="34%">-->
    <!--      <img :src="imgUrl" alt="" width="100%"/>-->
    <!--    </el-dialog>-->

  </div>
</template>

<script>
export default {
  name: "applyHis",

  data() {
    return {
      deviceTitle: '',
      titile: '',
      deviceVideo: '',
      deviceBook: '',
      // videoss: false,
      videos: '',
      bookList: [],
      qiniuUrl: "http://hpadvideo.u-home-safe.com/picAvideo/",
      lookImg: false,
      imgUrl: "",
      videoList: [], // 视频会有多个
      videoNameList: [], // 视频对应的标题
      nameTi: "",
    }
  },

  methods: {
    goBack() {
      this.$router.back();
    },
    seeBook1(imgUrl) {
      this.imgUrl = imgUrl;
      this.lookImg = true;
    }

  },

  mounted: function () {
    let id1 = this.$route.query.id;
    let id = {
      "id": id1
    }
    this.$axios.post(this.url + "/querydeviceById", id).then(res => {
      console.log("querydeviceById2222:", res);
      this.bookList = res.data.booklist.split("&");
      this.videoList = res.data.mpurl.split("&");
      this.videoNameList = res.data.remark.split("&");
      this.titile = res.data.name;
      this.nameTi = res.data.logourl;
    })
  }
}

</script>

<style>
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 40px;
}
</style>