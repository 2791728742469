<template>
  <div>
  </div>
</template>

<script>
// const url = "http://localhost:8096";
const url = "http://hpadvideo.u-home-safe.com";
export default {
  url
}
</script>