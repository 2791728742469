<template>

  <div style="margin: 10px 30px">

    <div style="display: flex;flex-direction: row;height: 40px;margin-top: 50px">
      <el-input style="height: 40px;width: 50%;margin-left: 22%" placeholder="请输入型号" v-model="inputVal"
                class="input-with-select"></el-input>
      <el-button type="primary"
                 style="background-color: #409eff;color: white;margin-left: 10px;height: 40px;margin-right: 10%"
                 icon="el-icon-search"
                 @click="selectList()">搜索
      </el-button>
    </div>

    <div style="height: 50px"></div>
    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName" max-height="600">
      <el-table-column style="color:red;height:2px;" prop="id" label="编号" min-width="5%"></el-table-column>
      <el-table-column prop="logourl" label="主图" min-width="8%">
        <template slot-scope="scope">
          <img :src="qiniuUrl+scope.row.logourl" width="60" height="60"/>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" min-width="8%"></el-table-column>
      <el-table-column prop="type" label="分类" min-width="8%"></el-table-column>
      <el-table-column prop="remark" label="视频标题" min-width="16%"></el-table-column>
      <el-table-column prop="mpurl" label="操作视频" min-width="16%"></el-table-column>
      <el-table-column prop="booklist" label="说明书" min-width="14%"></el-table-column>

      <el-table-column label="查看" fixed="right" min-width="15%">
        <template slot-scope="scope">
          <el-button class="el-icon-view" size="primary" style="background-color: white;color: #000000;border:none"
                     @click="seeVideo(scope.row.mpurl)">视频
          </el-button>
          <el-button class="el-icon-view" size="primary"
                     style="margin-left: 8px;border:none;background-color: white;color: #000000"
                     type="danger"
                     @click="seeBooks(scope.row.booklist)">
            说明书
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" min-width="15%">
        <template slot-scope="scope">
          <el-button size="primary" @click="updateDevice(scope.row)">编辑</el-button>
          <el-button size="primary" style="margin-left: 8px" type="danger" @click="delDevice(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog :visible.sync="lookImg" :modal="false" title="主图预览" width="34%">
      <img :src="imgUrl" alt="" width="100%"/>
    </el-dialog>

    <el-dialog :visible.sync="book" :modal="false" title="说明书预览" width="34%">
      <div v-for="(pro,index) in bookList" :key="index">
        <img :src="qiniuUrl+pro" alt="" width="100%"/>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="lookVideo" :modal="false" title="视频预览" width="34%">
      <div v-for="(pro,index) in videoList" :key="index" style="margin-top: 30px">
        <video style="width: 34%" controls>
          <source :src="qiniuUrl+pro" type="video/mp4">
        </video>
      </div>
    </el-dialog>


    <el-dialog title="修改产品" :visible.sync="seeUpdate" width="40%" center
               style="min-width: 800px">
      <div style="margin-top: 50px;display: flex;flex-direction: row;width: 100%;">
        <div style="line-height: 40px;margin-left: 21%"> 设备类:</div>
        <el-select style="margin-left: 1%" v-model="select.select" placeholder="请选择">
          <el-option label="普板类" value="1"></el-option>
          <el-option label="CSP类" value="2"></el-option>
        </el-select>
      </div>

      <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%;">
        <div style="line-height: 40px;margin-left: 21%">主图名:</div>
        <el-input style="width:49%;margin-left: 1%" placeholder="请输入主图名" v-model="deviceImg"></el-input>
        <el-button style="height: 40px" @click="seeDeviceImg()">预览</el-button>
      </div>

      <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
        <div style="line-height: 40px;margin-left: 21%">系列名:</div>
        <el-input style="width:49%;margin-left: 1%" placeholder="请输入标题" v-model="deviceTitle"></el-input>
      </div>

      <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
        <div style="line-height: 40px;margin-left: 21%">标题名:</div>
        <el-input style="width:49%;margin-left: 1%" placeholder="请输入视频标题名称" v-model="videoName"></el-input>
      </div>

      <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
        <div style="line-height: 40px;margin-left: 21%">视频名:</div>
        <el-input style="width:49%;margin-left: 1%" placeholder="请输入视频名" v-model="deviceVideo"></el-input>
<!--        <el-button @click="seeDeviceVideo()">预览</el-button>-->
      </div>

      <div style="margin-top: 30px;display: flex;flex-direction: row;width: 100%">
        <div style="line-height: 40px;margin-left: 21%">说明书:</div>
        <el-input style="width:49%;margin-left: 1%" placeholder="请输入说明书单页名称，多页以&分割"
                  v-model="deviceBook"></el-input>
        <el-button @click="seeDviceBook()">预览</el-button>
      </div>

      <div style="display: flex;flex-direction: row;justify-content: center;margin: 60px 220px">
        <el-button @click="clearInput()" style="width: 120px">取消</el-button>
        <el-button @click="updateDevices()" style="width: 120px">确认</el-button>
      </div>
    </el-dialog>


  </div>

</template>

<script>
export default {
  name: "applyList",

  data() {
    return {
      inputVal: "",
      tableData: [],
      bookList: [],
      qiniuUrl: "http://video.u-home-safe.com/",
      lookImg: false,
      imgUrl: '',
      book: false,
      bookUrl: '',
      select: {
        select: '',
        inputVal: '',
      },
      seeUpdate: false,
      deviceImg: '',
      deviceTitle: '',
      deviceVideo: '',
      deviceBook: '',
      id: "",
      lookVideo: false,
      videoUrl: "",
      videoList: [],
      videoName: "",
    }
  },

  methods: {
    seeVideo(url) {
      this.lookVideo = true;
      this.videoList = url.split("&");
    },
    // 设置灰色
    tableRowClassName({rowIndex}) {
      if (rowIndex > 0 && rowIndex % 2 === 1) {
        return 'warning-row';
      }
      return '';
    },
    updateDevices() {
      const dataDevice = {
        "type": this.select.select,
        "id": this.id,
        "deviceImg": this.deviceImg,
        "deviceTitle": this.deviceTitle,
        "deviceVideo": this.deviceVideo,
        "deviceBook": this.deviceBook,
        "videoName": this.videoName
      };
      this.$axios.post(this.url + "/updatedevice", dataDevice).then(res => {
        console.log("addDevice:", res);
        if (res.status === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getAllList();
          this.seeUpdate = false;
        } else {
          this.$message({
            message: '异常',
            type: 'error'
          });
        }
      });
    },
    clearInput() {
      this.seeUpdate = false;
    },
    updateDevice(device) {
      this.seeUpdate = true;
      this.id = device.id;
      let select = device.type === "普板" ? "1" : "2";
      this.select.select = select;
      this.deviceImg = device.logourl;
      this.deviceTitle = device.name;
      this.deviceVideo = device.mpurl;
      this.deviceBook = device.booklist;
      this.videoName = device.remark;
    },
    seeDeviceImg() {
      this.imgUrl = this.qiniuUrl + this.deviceImg;
      this.lookImg = true;
    },
    seeDeviceVideo() {
      this.videoUrl = this.qiniuUrl + this.deviceVideo;
      this.lookVideo = true;
    },
    seeDviceBook() {
      this.book = true;
      let book = this.deviceBook;
      this.bookList = book.split("&");
    },

    delDevice(id) {
      const dataDevice = {
        "id": id,
      };
      this.$confirm("是否删除该产品？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post(this.url + "/deldevice", dataDevice).then(res => {
          console.log("res:", res);
          this.getAllList();
        })
      })
    },

    seeBooks(bookstr) {
      this.book = true;
      this.bookList = bookstr.split("&");
    },
    seeDeviceImgs(rurl) {
      this.imgUrl = rurl;
      this.lookImg = true;
    },
    //addNewVideo 发布新的产品视频，在新页面提交表单
    selectList() {
      const dataDevice = {
        "deviceTitle": this.inputVal,
      };
      console.log("dataDevice", dataDevice)
      this.$axios.post(this.url + "/querylikedevice", dataDevice).then(res => {
        console.log("res:", res);
        this.tableData = res.data
      })
    },

    getAllList: function () {
      this.$axios.post(this.url + "/querydevice").then(res => {
        console.log("res:", res);
        this.tableData = res.data
      })
    },
  },

  mounted: function () {
    //自动触发写入的函数
    this.getAllList();
  }

}

</script>
