<template>
  <div style="margin: 0px auto;;width: 375px;font-size: 15px;background-color: #ffffff">
    <div style="height: 30px;"></div>
    <div style="width: 375px;display: flex;flex-direction: row;justify-content: center;height: 40px;">
      <el-input style="height: 30px;width: 260px;font-size: 13px"
                placeholder="请输入搜索的设备型号"
                v-model="inputVal"
                class="input-with-select">
        <el-button slot="append" @click="selectList()" icon="el-icon-search">搜索</el-button>
      </el-input>
    </div>


    <div style="border-radius: 10px;margin-top: 20px">
      <div v-if="typeD === 1" style="display: flex;flex-direction: row;border-radius: 10px;">
        <div style="width: 75px;font-size: 14px;text-align: center;background-color: #f8f8f8;
        border-top-left-radius: 10px;border-bottom-left-radius: 10px;position: absolute;height: 600px">
          <div style="line-height: 80px;color: rgb(238,128,90);background-color: #ffffff">普板类</div>
          <div style="line-height: 80px;color: rgb(153,153,153);" @click="typeD=2">CSP类</div>
        </div>

        <div
            style="width: 300px;height: 600px;margin-left: 80px;display: flex;flex-wrap: wrap;align-content: flex-start;overflow: auto;background-color: #ffffff;border-top-right-radius: 10px;border-bottom-right-radius: 10px">
          <div v-for="(pro,index) in tablePB" :key="index"
               style="font-size: 18px;width: 98px; text-align: center;height: 120px;">
            <div style="height: 86px;padding-top: 5px;margin: 0 0;">
              <img @click="deviceDetail(pro.id)" style="width: 80px;height: 80px;" :src="qiniuUrl+pro.logourl">
            </div>
            <div
                style="width:80px;color: rgb(56,56,56);font-size: 12px;margin-left: 10px;margin-top: 3px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap; ">
              {{ pro.name }}
            </div>
          </div>

        </div>
      </div>

      <div v-if="typeD === 2"
           style="display: flex;flex-direction: row;border-radius: 10px;">
        <div style="width: 75px;font-size: 14px;text-align: center;background-color: #f8f8f8;
       ;border-bottom-left-radius: 10px;position: absolute;height: 600px">
          <div style="line-height: 80px;color: rgb(153,153,153);" @click="typeD=1">普板类</div>
          <div style="line-height: 80px;color: rgb(238,128,90);background-color: #ffffff">CSP类</div>
        </div>
        <div
            style="width: 300px;height: 600px;display: flex;margin-left: 80px;flex-wrap: wrap;align-content: flex-start;overflow: auto;background-color: #ffffff;">
          <div v-for="(pro,index) in tableCSP" :key="index"
               style="font-size: 18px;width: 98px;
             text-align: center;height: 120px">
            <div style="height: 86px;padding-top: 5px;margin: 0 5px">
              <img @click="deviceDetail(pro.id)" style="width: 80px;height: 80px;" :src="qiniuUrl+pro.logourl">
            </div>
            <div
                style="width:80px;color: rgb(56,56,56);margin-left: 10px;height:18px;overflow: hidden;text-overflow: ellipsis;font-size: 12px;white-space: nowrap; ">
              {{ pro.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "applyList",

  data() {
    return {
      tableData: [],
      tablePB: [],
      tableCSP: [],
      typeD: 1,
      inputVal: "",
      qiniuUrl: "http://hpadvideo.u-home-safe.com/picAvideo/",
      // qiniuUrl: "http://video.u-home-safe.com/",
    }
  },

  methods: {
    // 扫描
    scane() {

    },

    // 设备详情
    deviceDetail(id) {
      console.log("click id:", id);
      this.$router.push("/auserdetail?id=" + id);
    },
    selectList() {
      console.log("blur tauch")
      const dataDevice = {
        "deviceTitle": this.inputVal,
      };
      console.log("dataDevice", dataDevice);
      this.tableData = [];
      this.tablePB = [];
      this.tableCSP = [];
      this.$axios.post(this.url + "/querylikedevice", dataDevice).then(res => {
        console.log("res:", res);
        this.tableData = res.data;
        let tableData = this.tableData;
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].type === "普板") {
            this.tablePB.push(tableData[i]);
            this.typeD = 1;
          }
          if (tableData[i].type === "CSP") {
            this.tableCSP.push(tableData[i]);
            this.typeD = 2;
          }
        }
        this.typeD = 1;
      })
    },

    getAllList: function () {
      this.$axios.post(this.url + "/querydevice").then(res => {
        console.log("res:", res);
        this.tableData = res.data;
        let tableData = this.tableData;
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].type === "普板") {
            this.tablePB.push(tableData[i])
          }
          if (tableData[i].type === "CSP") {
            this.tableCSP.push(tableData[i])
          }
        }
        console.log("")
      })
    },
  },

  mounted: function () {
    //自动触发写入的函数
    this.getAllList();
  }

}

</script>

<style>
.el-input .el-input__inner {
  background-color: rgb(246, 246, 246);
  height: 32px;
  line-height: 32px;
}
</style>