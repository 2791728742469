<template>
  <div style="background-color: #e8e8e8;width: 100%;height: 100%;padding-top: 220px">

    <div class="register-ruleFormPsw">
      <div style="font-size: 20px;margin-bottom: 40px">
        注册账号
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="账号：" prop="oldPsw">
          <label slot="label">账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:</label>
          <el-input v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="psw">
          <label slot="label">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:</label>
          <el-input v-model="ruleForm.psw1" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="psw">
          <el-input v-model="ruleForm.psw2" type="password"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="pswTo">
          <el-input v-model="ruleForm.phone" type="number" placeholder="请输入管理员手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="psw">
          <div style="display: flex;flex-direction: row">
            <el-input v-model="ruleForm.phoneCode" type="number">
            </el-input>
            <button style="width: 50%;margin-left: 10px" @click="getPhoneCode()">{{ desc }}</button>
          </div>
        </el-form-item>
        <el-form-item style="margin-top: 50px;margin-left: -80px">
          <el-button type="primary" @click="registerAccount()">确认注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "forget",
  data() {
    return {
      ruleForm: {
        account: '',
        psw1: '',
        psw2: '',
        phone: '',
        phoneCode: '',
      },
      desc: '获取验证码',
      click: '1', // 防重复点击
    };
  },
  methods: {
    // 注册获取验证码
    getPhoneCode() {
      if (this.click == '2') {
        this.$message.warning('请勿重复点击');
      } else {
        if (this.ruleForm.phone != null || this.ruleForm.phone != '') {
          // 后端登录业务
          this.$axios.post(this.url + "/sys/accGetCode", this.ruleForm).then(res => {
            if (res.data == 201) {//登录失败
              this.$message.error('获取失败，输入的号码非系统管理员号码');
            } else {
              this.$message({
                message: '已发送',
                type: 'success'
              });
              // 改变文字
              this.desc = '10分钟有效';
              this.click = '2';

              setTimeout(() => {
                this.desc = '获取验证码';
                this.click = '1';
              }, 600 * 1000)

            }
          })
        } else {
          this.$message({
            message: '号码不能为空',
            type: 'warning'
          });
        }
      }
    },

    // 确认注册
    registerAccount() {
      // 输入不为空提交
      if (this.ruleForm.account == '' || this.ruleForm.account == null) {
        this.$message.warning('账号不能为空！');
        return;
      }

      if (this.ruleForm.psw1 == '' || this.ruleForm.psw1 == null) {
        this.$message.warning('密码不能为空！');
        return;
      }

      if (this.ruleForm.psw2 == '' || this.ruleForm.psw2 == null) {
        this.$message.warning('密码不能为空！');
        return;
      }

      if (this.ruleForm.phone == '' || this.ruleForm.phone == null) {
        this.$message.warning('手机号不能为空！');
        return;
      }
      if (this.ruleForm.phoneCode == '' || this.ruleForm.phoneCode == null) {
        this.$message.warning('验证码不能为空！');
        return;
      }

      if (this.ruleForm.psw1 !== this.ruleForm.psw2) {
        this.$message.error('两次输入密码不一致！');
        return;
      }

      this.$axios.post(this.url + "/sys/registerAccount", this.ruleForm).then(res => {

        if (res.data == 201) {
          this.$message.error('当前用户名已存在！');
          return;
        }

        if (res.data == 202) {
          this.$message.error('手机号错误！');
          return;
        }

        if (res.data == 203) {
          this.$message.error('验证码错误！');
          return;
        }

        if (res.data == 204) {
          this.$message.error('验证码已失效！');
          return;
        }

        if (res.data == 205) {
          this.$message.error('账号注册已满！');
          return;
        }


        if (res.data == 200) {
          this.$message.success('注册成功,3秒后跳转登录界面！');
          this.ruleForm.account = '';
          this.ruleForm.phone = '';
          this.ruleForm.psw1 = '';
          this.ruleForm.psw2 = '';
          this.ruleForm.phoneCode = '';
          this.desc = "验证码";
          this.click = "1";

          setTimeout(() => {
            console.log("555555555:", this)
            this.$router.replace("/login");
          }, 3000);

          return;
        }
      })

    },
    //延迟的方法
  }
}
</script>
<style>
.register-ruleFormPsw {
  text-align: center;
  border-radius: 15px;
  background-clip: padding-box;
  margin: 0px auto;
  /*margin-left: 200px;*/
  width: 400px;
  padding: 50px 35px 15px 35px;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px rgb(233, 233, 233);
  opacity: 1;
}
</style>
