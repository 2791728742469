<template>
  <div style="background-color: #ffffff;
    margin: 180px auto;
    width: 600px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 15px 4px -15px #d8d8d8;">

    <div style="margin: 10px auto;width: 360px;color: black;text-align: left">
      当前绑定：{{ nowPhone }}
    </div>

    <div style="margin: 25px auto;width: 360px;display: flex;text-align: left">
      <el-input placeholder="请输入新手机号" v-model="newPhone.newPhone">
      </el-input>
    </div>


    <div v-if="admin == 'true'">
      <div style="margin: 10px auto;width: 360px;display: flex">
        <el-input placeholder="请输入验证码" v-model="newPhone.verCode" class="input-with-select" style="margin-right: 10px;">
        </el-input>
        <el-button style="background-color: #409eff;color: white" slot="append"
                   @click="getVercode()">{{ verDesc }}
        </el-button>
      </div>
    </div>

    <div v-if="admin == 'false'">
      <div style="margin: 10px auto;width: 360px;display: flex">
        <el-input placeholder="请输入验证码" v-model="newPhone.verCode" class="input-with-select" style="margin-right: 10px;">
        </el-input>
        <el-button style="background-color: #409eff;color: white" slot="append"
                   @click="noPower()">{{ verDesc }}
        </el-button>
      </div>
    </div>

    <p style="font-size: 12px;color: #8c9396;margin: 20px 120px 0 120px;text-align: left">
      绑定管理员号码，可以用于系统登录和找回密码，若设置的微信管理员没有关注公众号，会将申请以短信形式发送到该手机上。</p>
    <el-button style="margin-top: 60px" type="primary" @click="modifyPhone()">确认修改</el-button>

  </div>
</template>

<script>

export default {
  name: "setPhone",

  data() {
    return {
      nowPhone: '',
      newPhone: {
        newPhone: '',
        verCode: '',
      },
      verDesc: '获取验证码',
      admin: 'true',
    }
  },

  methods: {
    noPower(){
      this.$message.warning('无权限');
    },

    // 获取管理员信息
    getAdminInfo() {
      // 后台发送验证码
      this.$axios.post(this.url + "/sys/adminInfo").then(res => {
        console.log("res data:", res);
        this.nowPhone = res.data.phone;
      })
    },

    // 获取验证码
    getVercode() {
      var phone = this.newPhone.newPhone;
      if (phone == null || phone == '') {
        this.$message.error('新号码不能为空');
        return;
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
        this.$message({
          message: '号码格式错误，请重新输入',
          type: 'warning'
        });
        return;
      }
      // 后台发送验证码
      if (this.verDesc == "获取验证码") {
        this.$axios.post(this.url + "/sys/getVercode", this.newPhone).then(res => {
          console.log("res data:", res);
          if (res.data == 200) {
            this.$message({
              message: '已发送',
              type: 'success'
            });
            this.verDesc = "十分钟有效";
            setTimeout(function () {
              this.verDesc = "获取验证码";
            }, 60 * 1000 * 10)
          } else {
            this.$message({
              message: '发送失败',
              type: 'warning'
            });
          }
        })
      }
    },
    // 提交修改
    modifyPhone() {
      this.$axios.post(this.url + "/sys/submitPhone", this.newPhone).then(res => {
        console.log("submitPhone: ", res)
        if (res.data == 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.newPhone.newPhone = '';
          this.newPhone.verCode = "";
          this.verDesc = '获取验证码';
          this.getAdminInfo();
        } else {
          this.$message({
            message: '修改失败',
            type: 'warning'
          });
        }
      })
    },
  },
  mounted: function () {   //自动触发写入的函数
    this.getAdminInfo();

    // 获取用户登录身份 手机号或者是登录的用户名
    var user = window.sessionStorage.getItem("user");
    console.log("session user:", user);
    // 后台管理员号码
    this.$axios.post(this.url + "/sys/adminInfo").then(res => {
      var adminPhone = res.data.phone;
      console.log("res data adminPhone:", adminPhone);

      if (user == "DaMuZhi666") {
        this.admin = 'true';
      } else if (user == adminPhone) {
        this.admin = 'true';
      } else {
        this.admin = 'false';
      }

      console.log(" user == adminPhone : ", user == adminPhone)
      console.log(" this.admin : ", this.admin)
    })


  }
}

</script>

<style>

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

</style>